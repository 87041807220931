import React from "react";
import { Pagination } from "react-bootstrap";

import "../layout/PaginationProducts.css";

export default function PaginationProducts({ metaData, onPageChange }) {
  const { currentPage, totalPages } = metaData;

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      paginationItems.push(
        <Pagination.Item
          key={pageNumber}
          className={`pagination-item ${
            pageNumber === currentPage ? "active" : ""
          }`}
          onClick={() => {
            onPageChange(pageNumber);
            scrollToTop();
          }}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }
    return paginationItems;
  };

  return (
    <Pagination className='pagination'>
      {currentPage === 1 ? (
        ""
      ) : (
        <Pagination.Prev
          className={`pagination-item `}
          onClick={() => {
            onPageChange(currentPage - 1);
            scrollToTop();
          }}
        />
      )}
      {renderPaginationItems()}
      {currentPage === totalPages ? (
        ""
      ) : (
        <Pagination.Next
          className={`pagination-item`}
          onClick={() => {
            onPageChange(currentPage + 1);
            scrollToTop();
          }}
        />
      )}
    </Pagination>
  );
}
