import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import slika from "../../namestaj/namestaj/tapaciraniNamestaj/ugaone/Vetro.jpg";

const SkeletonCard = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loadingTimer);
  }, []);

  return (
    <Container>
      <Link to='/' className='linkWraper'>
        <Card
          className={`mb-4 pointer ${isLoading ? "skeleton-loading" : ""}`}
          onMouseEnter={() => {
            setIsLoading(true);
          }}
          onMouseLeave={() => {
            setIsLoading(false);
          }}
        >
          <div className='image-wrapper'>
            <div style={{ maxWidth: "100%", overflow: "hidden" }}>
              {isLoading ? (
                <Skeleton
                  sx={{ width: "100%", height: 190 }}
                  animation='wave'
                  variant='rectangular'
                />
              ) : (
                <Card.Img className='CardImg' variant='top' src={slika} fluid />
              )}
            </div>
            <Card.Body
              className={`cardBody ${isLoading ? "skeleton-loading" : ""}`}
            >
              {isLoading ? (
                <>
                  <Skeleton
                    animation='wave'
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation='wave' height={10} width='80%' />
                </>
              ) : (
                <Card.Title>Naziv proizvoda</Card.Title>
              )}
            </Card.Body>
          </div>
        </Card>
      </Link>
    </Container>
  );
};

export default SkeletonCard;
