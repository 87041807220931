import React, { useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SkeletonCard from "../tapaciraniNamestaj/SkeletonCard";
import "./KlubStoCss.css";

const KlubStoCard = ({ product }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { tNamestajLoaded } = useSelector((state) => state.tNamestaj);
  // const elementi = product?.elementi;

  const slike = product?.slike || [];

  const slika1 = slike[0];
  const slika2 = slike[1];

  return (
    <>
      <Container>
        {!tNamestajLoaded ? (
          <SkeletonCard />
        ) : (
          <>
            <Link className='linkWraper' to={`/klubsto/${product.proizvodId}`}>
              <Card
                key={product.proizvodId}
                className={`mb-4 pointer ${isHovered ? "hovered" : ""}`}
                onMouseEnter={() => {
                  setIsHovered(true);
                  document
                    .getElementById(product.proizvodId)
                    ?.classList.add("enter-transition");
                }}
                onMouseLeave={() => {
                  setIsHovered(false);
                  document
                    .getElementById(product.proizvodId)
                    ?.classList.remove("enter-transition");
                }}
              >
                <div className='image-wrapper'>
                  <div style={{ maxWidth: "100%", overflow: "hidden" }}>
                    <Card.Img
                      className={`CardImgKlubSto ${isHovered ? "hovered" : ""}`}
                      variant='top'
                      src={
                        isHovered ? slika2.putanjaSlike : slika1.putanjaSlike
                      }
                    />
                  </div>
                  <Card.Body
                    className={`cardBody ${isHovered ? "hovered" : ""}`}
                  >
                    <Card.Title>Klub sto {product.naziv}</Card.Title>
                  </Card.Body>
                </div>
              </Card>
            </Link>
          </>
        )}
      </Container>
    </>
  );
};

export default KlubStoCard;
