import React from "react";
import Video from "../namestaj/ezgif.com-video-to-gif.gif";
import { Image } from "react-bootstrap";

export const VideoComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
      }}
    >
      <Image fluid src={Video} width='350px' alt='animacija' />
    </div>
  );
};
