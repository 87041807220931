import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import { VideoComponent } from "../VideoComponent";
import "./Main.css";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";

import couch from "../../namestaj/couch.png";
import tvPolice from "../../namestaj/tv.png";
import lezaj from "../../namestaj/bedroom.png";
import diningroom from "../../namestaj/diningroom.png";
import klubsto from "../../namestaj/table.png";
import img1 from "../../namestaj/NEK-5552 copy.jpg";
import img2 from "../../namestaj/_mzf2101105 copy (1).jpg";
import img3 from "../../namestaj/_mzf2101211 copy (1).jpg";
import stolica1 from "../../namestaj/202203151944501936358.jpg";
import spavaca1 from "../../namestaj/spavaca.jpg";
import komoda1 from "../../namestaj/vitrina1.jpg";
import trpezarija1 from "../../namestaj/diana.jpg";
import sofaSet from "../../namestaj/aura.jpg";
import viennasto from "../../namestaj/viennasto.png";
import ScrollAnimationX from "../ScrollAnimationX";
import ScrollAnimationY from "../ScrollAnimationY";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import HeaderNav from "../Header/HeaderNav";

export default function Main() {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [slidesPerView, setSlidesPerView] = useState(getInitialSlidesPerView());

  function getInitialSlidesPerView() {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 576) {
      return 2;
    } else if (screenWidth <= 768) {
      return 2;
    } else if (screenWidth <= 992) {
      return 3;
    } else {
      return 4;
    }
  }

  useEffect(() => {
    function handleResize() {
      setSlidesPerView(getInitialSlidesPerView());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const hasLoaded = localStorage.getItem("hasLoaded");
    if (hasLoaded) {
      setLoading(false);
    } else {
      const timer = setTimeout(() => {
        setLoading(false);
        localStorage.setItem("hasLoaded", true);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading2(false);
    }, 300);

    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      {loading ? (
        <div className='loading-container'>
          <div className='loading-spinner'>
            <VideoComponent />
          </div>
        </div>
      ) : (
        <>
          {/* Sliders */}
          {loading2 ? (
            <>
              <HeaderNav />
              <div
                style={{
                  height: "65vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                Učitavanje...
              </div>
            </>
          ) : (
            <>
              <Header />
              <Container className='mt-5 mb-3'>
                <Row>
                  <h2 className='h2Proizvodi'>Naša ponuda po kategorijama</h2>
                  <div className='dflexCenter'>
                    <hr className='hrProizvodi' />
                  </div>
                  <section className='my-5'>
                    <Swiper
                      slidesPerView={slidesPerView}
                      spaceBetween={1}
                      freeMode={false}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[FreeMode, Pagination]}
                      className='mySwiper'
                    >
                      {" "}
                      {/* <SwiperSlide>
                    <div className='swiperSlideHover'>
                      <div className='dflexCenter '>
                        <Image className='image_slider' src={concepts} fluid />
                      </div>
                      <div className='sliderh4'>
                        <h4 className='sliderh4'>Koncepti</h4>
                      </div>
                    </div>
                  </SwiperSlide> */}
                      <SwiperSlide>
                        <Link
                          to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+nameštaj&potkategorije=setovi&potkategorije=ugaone+garniture'
                          className='my-link'
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <div className='swiperSlideHover'>
                            <div className='dflexCenter '>
                              <Image
                                className='image_slider'
                                src={couch}
                                fluid
                              />
                            </div>
                            <div className='sliderh4'>
                              <h4 className='sliderh4'>
                                Tapacirani <br /> nameštaj
                              </h4>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link
                          to='https://www.elitehome.rs/tvVitrine?kategorije=TV+vitrine'
                          className='my-link'
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <div className='swiperSlideHover'>
                            <div className='dflexCenter'>
                              <Image
                                src={tvPolice}
                                fluid
                                className='image_slider'
                              />
                            </div>
                            <div className='sliderh4'>
                              <h4 className='sliderh4'>TV vitrine</h4>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link
                          to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=trpezarije'
                          className='my-link'
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <div className='swiperSlideHover'>
                            <div className='dflexCenter'>
                              <Image
                                src={diningroom}
                                fluid
                                className='image_slider'
                              />
                            </div>
                            <div className='sliderh4'>
                              <h4 className='sliderh4'>Trpezarije</h4>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link
                          to='https://www.elitehome.rs/spavaceSobe?kategorije=spavaće+sobe&potkategorije=spavaća+soba'
                          className='my-link'
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <div className='swiperSlideHover'>
                            <div className='dflexCenter'>
                              <Image
                                src={lezaj}
                                fluid
                                className='image_slider'
                              />
                            </div>
                            <div className='sliderh4'>
                              <h4 className='sliderh4'>Spavaće sobe</h4>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Link
                          to='https://www.elitehome.rs/klubSto?kategorije=klub+stolovi&potkategorije=klub+sto'
                          className='my-link'
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <div className='swiperSlideHover'>
                            <div className='dflexCenter'>
                              <Image
                                src={klubsto}
                                fluid
                                className='image_slider'
                              />
                            </div>
                            <div className='sliderh4'>
                              <h4 className='sliderh4'>Klub stolovi</h4>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>{" "}
                    </Swiper>
                  </section>
                </Row>
              </Container>

              {/* Zasto izabrati nas */}

              <h3 className='h2Proizvodi'>Zašto izabrati nas?</h3>
              <div className='dflexCenter'>
                <hr className='hrProizvodi' />
              </div>
              <Container fluid>
                <Row className='txtMain'>
                  <div className='containerChoose'>
                    <Row>
                      <Col sm={12} md={4} className='mdContainer50'>
                        <div className='containerChooseImage'>
                          <div className='image-container'>
                            <Image src={img1} fluid rounded />

                            <div className='image-text txtMain'>
                              <h3 className='textImageh3'>
                                Oplemenite svoj dom
                              </h3>
                            </div>

                            <div className='image-text1 txtMain'>
                              <p className='textImageP'>
                                U našoj ponudi možete pronaći udobne sofe,
                                elegantne stolove i praktične ormare koji će
                                oplemeniti vaš prostor. Uživajte u
                                funkcionalnosti i estetici s našim vrhunskim
                                nameštajem koji će stvoriti željeni ambijent u
                                vašem domu.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={4} className='mdContainer50'>
                        <div className='containerChooseImage'>
                          {" "}
                          <div className='image-container'>
                            <Image src={img2} fluid rounded />

                            <div className='image-text txtMain'>
                              <h3 className='textImageh3'>Izaziva divljenje</h3>
                            </div>

                            <div className='image-text1 txtMain'>
                              <p className='textImageP'>
                                Stil se ne može definisati rečima, ali ga svako
                                može odmah prepoznati. Nastaje spajanjem
                                trendova s iskustvom, čak i njihovim
                                usmeravanjem, jer svi prate modu, mi želimo da
                                aktivno učestvujemo u njenom kreiranju.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={4} className='mdContainer50'>
                        <div className='containerChooseImage'>
                          <div className='image-container'>
                            <Image src={img3} fluid rounded />

                            <div className='image-text txtMain'>
                              <h3 className='textImageh3'>
                                Savremeno i moderno
                              </h3>
                            </div>

                            <div className='image-text12 txtMain'>
                              <p className='textImageP'>
                                Ekskluzivan salon nameštaja u Novom Pazaru sa
                                savremenim načinom prodaje i prezentacije
                                proizvoda kroz kreiranje prostora koji odražava
                                moderan koncept življenja.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Container>

              <Container>
                <Row>
                  <Col md={6}>
                    <div className='containerStolice'>
                      <Image className='stoliceImg' src={stolica1} fluid />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='containerStolice'>
                      <ScrollAnimationY sec={0.4}>
                        <h3 className='h3Stolice'>
                          Enterijeri kafića i restorana
                        </h3>
                        <div className='dflexCenter'>
                          <hr className='hrProizvodi' />
                        </div>
                      </ScrollAnimationY>

                      <ScrollAnimationY sec={1.1}>
                        <p className='pStolice pStoliceS'>
                          Dizajn enterijera igra ključnu ulogu u stvaranju
                          nezaboravnog iskustva za goste u kafićima i
                          restoranima. Ova tema istražuje najnovije trendove,
                          savete i inspiraciju za dizajn enterijera koji će
                          očarati posetitelje i istaknuti vašu ugostiteljsku
                          lokaciju. Od minimalističkih koncepta do rustikalnih
                          industrijskih stilova, otkrijte kako transformisati
                          prostor u jedinstvenu oazu koja privlači goste iznova
                          i iznova.
                        </p>
                      </ScrollAnimationY>
                    </div>
                  </Col>
                </Row>
              </Container>

              <Container>
                <Row>
                  <Col md={3} className='px-0 mdContainer50'>
                    <Link
                      to='https://www.elitehome.rs/spavaceSobe/1'
                      className='my-link'
                    >
                      <div className='containerGalery'>
                        <Image src={spavaca1} fluid />
                        <div className='overlayGalery'>
                          <div className='textGalery'>
                            <span className='SpanGallery'>Spavaća soba</span>{" "}
                            <br /> Quatro
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col md={3} className='px-0 mdContainer50'>
                    <Link
                      to='https://www.elitehome.rs/trpezarije/95'
                      className='my-link'
                    >
                      <div className='containerGalery'>
                        <Image src={trpezarija1} fluid />
                        <div className='overlayGalery'>
                          <div className='textGalery'>
                            <span className='SpanGallery'>Trpezarija</span>{" "}
                            <br /> Diana
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col md={3} className='px-0 mdContainer50'>
                    {" "}
                    <Link
                      to='https://www.elitehome.rs/tapaciraniNamestaj/39'
                      className='my-link'
                    >
                      <div className='containerGalery'>
                        <Image src={sofaSet} fluid />
                        <div className='overlayGalery'>
                          <div className='textGalery'>
                            <span className='SpanGallery'>Sofa set</span> <br />{" "}
                            Aura
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col md={3} className='px-0 mdContainer50'>
                    {" "}
                    <Link
                      to='https://www.elitehome.rs/tvVitrine/92'
                      className='my-link'
                    >
                      <div className='containerGalery'>
                        <Image src={komoda1} fluid />
                        <div className='overlayGalery'>
                          <div className='textGalery'>
                            <span className='SpanGallery'>TV vitrina</span>{" "}
                            <br /> Valentino
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                </Row>
              </Container>

              <Container fluid>
                <Row>
                  <Col className='px-0' md={12}>
                    <div className='GalleryDiv' />
                  </Col>
                </Row>
              </Container>

              <Container>
                <Row>
                  <Col md={6}>
                    <div className='containerStolice'>
                      <ScrollAnimationX sec={0.4}>
                        <h3 className='h3KlubSto'>Izrada nameštaja po meri</h3>
                        <div className='dflexCenter'>
                          <hr className='hrProizvodi' />
                        </div>
                      </ScrollAnimationX>

                      <ScrollAnimationX sec={1.1}>
                        <p className='pStolice pStoliceM'>
                          Izrada nameštaja po meri omogućava vam da stvorite
                          unikatni prostor koji odražava vaš stil i potrebe. Ova
                          tema istražuje prednosti saradnje s profesionalnim
                          stolarima i dizajnerima kako biste dobili nameštaj
                          koji je jedinstven, funkcionalan i estetski privlačan.
                          Bez obzira trebate li rešenja za malu prostoriju,
                          specifične dimenzije ili posebne dizajnerske detalje,
                          otkrijte kako izrada nameštaja po meri može
                          transformisati vaš dom u prostor iz snova.
                        </p>
                      </ScrollAnimationX>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='containerStolice mt-4'>
                      <Image className='klubstoImg' src={viennasto} fluid />
                    </div>
                  </Col>
                </Row>
              </Container>

              <h3 className='h2Proizvodi mt-5'>Kontaktirajte nas</h3>
              <div className='dflexCenter'>
                <hr className='hrProizvodi' />
              </div>
              <Container className='mt-3'>
                <Row>
                  <Col md={12}>
                    <div id='kontakt'>
                      <div className='kontaktHolder'>
                        <h2 className='kontNaslov'>
                          Ne možete da pronađete odgovor koji tražite?
                        </h2>

                        <h2 className='kontNaslov1'>
                          Ne ustručavajte se da nas pozovete.
                        </h2>
                        <Link to='/kontakt' className='my-link'>
                          <Button className='kontaktButton'>kontakt</Button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          )}
          <Footer />
        </>
      )}
    </>
  );
}
