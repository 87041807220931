import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import HeaderNamestaj from "../../components/Header/HeaderNamestaj";
import Footer from "../../components/footer/Footer";

import error404 from "../../namestaj/error-404.png";

export default function NotFound({ message }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {message ? (
        <>
          <Container>
            <Row>
              <Col>
                <div className='not-found'>
                  <Image src={error404} fluid />
                  <h3 style={{ color: "red" }}>
                    {message ? message : "Stranica nije pronadjena."}
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          <HeaderNamestaj />
          <Container>
            <Row>
              <Col>
                <div className='not-found'>
                  <Image src={error404} fluid />
                  <h3 style={{ color: "red" }}>
                    {message ? message : "Stranica nije pronadjena."}
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>

          <Footer />
        </>
      )}
    </>
  );
}
