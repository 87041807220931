import React from "react";
import { ScaleLoader } from "react-spinners";
import { Col, Container, Row } from "react-bootstrap";

export default function LoadingComponent() {
  return (
    <Container>
      <Row>
        <Col>
          <div className='loader'>
            <ScaleLoader color='#2f3542' size={70} />
            {/* <GridLoader color='#2f3542' /> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
