import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import "./Overlay.css";

// import concepts from "../../namestaj/interior-design.png";
import couch from "../../namestaj/couch_white.png";
import tv from "../../namestaj/tv-white.png";
import diningroom from "../../namestaj/chair-white.png";
import bedroom from "../../namestaj/bedroom-white.png";
import table from "../../namestaj/table-white.png";
import { Link } from "react-router-dom";

const OverlayNav = ({ onCloseNav }) => {
  const handleLinkClick = () => {
    const category = "tapacirani nameštaj";
    const subcategories = "setovi";
    const subcategories2 = "ugaone garniture";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategories);
    queryParams.append("potkategorije", subcategories2);

    const url = `https://www.elitehome.rs/tapaciraniNamestaj?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickSetovi = () => {
    const category = "tapacirani nameštaj";
    const subcategories = "Setovi";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategories);

    const url = `https://www.elitehome.rs/tapaciraniNamestaj?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClicUgaone = () => {
    const category = "tapacirani nameštaj";
    const subcategories = "Ugaone garniture";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategories);

    const url = `https://www.elitehome.rs/tapaciraniNamestaj?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickTrosedi = () => {
    const category = "tapacirani nameštaj";
    const subcategories = "trosed";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategories);

    const url = `https://www.elitehome.rs/tapaciraniNamestaj?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickDvosedi = () => {
    const category = "tapacirani nameštaj";
    const subcategories = "dvosed";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategories);

    const url = `https://www.elitehome.rs/tapaciraniNamestaj?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickFotelje = () => {
    const category = "tapacirani nameštaj";
    const subcategories = "fotelja";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategories);

    const url = `https://www.elitehome.rs/tapaciraniNamestaj?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickTvVitrine = () => {
    const category = "TV vitrine";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);

    const url = `https://www.elitehome.rs/tvVitrine?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickTrpezarije = () => {
    const category = "trpezarije";
    const subcategory = "trpezarije";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/trpezarije?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickSto = () => {
    const category = "trpezarije";
    const subcategory = "sto";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/trpezarije?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickStolica = () => {
    const category = "trpezarije";
    const subcategory = "stolica";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/trpezarije?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickKomoda = () => {
    const category = "trpezarije";
    const subcategory = "komoda";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/trpezarije?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickOgledalo = () => {
    const category = "trpezarije";
    const subcategory = "ogledalo";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/trpezarije?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickSpavaca = () => {
    const category = "spavaće sobe";
    const subcategory = "spavaća soba";
    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/spavaceSobe?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickSpavacaLezaj = () => {
    const category = "spavaće sobe";
    const subcategory = "ležaj";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/spavaceSobe?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickSpavacaGarderober = () => {
    const category = "spavaće sobe";
    const subcategory = "garderober";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/spavaceSobe?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickSpavacaNocniOrmaric = () => {
    const category = "spavaće sobe";
    const subcategory = "noćni ormarić";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/spavaceSobe?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickSpavacaKomode = () => {
    const category = "spavaće sobe";
    const subcategory = "komoda";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/spavaceSobe?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  const handleLinkClickSpavacaOgledala = () => {
    const category = "spavaće sobe";
    const subcategory = "ogledalo";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/spavaceSobe?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };
  const handleLinkClickKlubSto = () => {
    const category = "klub stolovi";
    const subcategory = "klub sto";

    const queryParams = new URLSearchParams();
    queryParams.append("kategorije", category);
    queryParams.append("potkategorije", subcategory);

    const url = `https://www.elitehome.rs/klubSto?${queryParams.toString()}`;
    window.location.href = url;
    onCloseNav();
    window.scrollTo(0, 0);
  };

  return (
    <div className='dropdown-menu dropdown-content ' aria-labelledby='nav-305'>
      <Container fluid>
        <Row className='justify-content-center'>
          {/* <Col xs={12} sm={6} md={4} lg={3} xl={2}>
            <div className='overlay-item'>
              <Image className='overlay-image' src={concepts} fluid />

              <Link to='/' className='my-link'>
                <p onClick={handleLinkClick} className='overlay-text'>
                  Koncepti
                </p>
              </Link>
              <div className='overlay-links'>
                <a href='/'>Aura</a>
                <a href='/'>Dio</a>
                <a href='/'>Senfoni</a>
                <a href='/'>Quatro</a>
                <a href='/'>Zara</a>
              </div>
            </div>
          </Col> */}
          <Col xs={12} sm={6} md={4} lg={3} xl={2}>
            <div className='overlay-item'>
              <Image className='overlay-image icon-nav' src={couch} fluid />

              <Link
                to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+name%C5%A1taj&potkategorije=setovi'
                className='my-link'
              >
                <p onClick={() => handleLinkClick()} className='overlay-text'>
                  Tapacirani nameštaj
                </p>
              </Link>
              <div className='overlay-links'>
                <Link
                  to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+nameštaj&potkategorije=Setovi'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickSetovi()}>Setovi</p>
                </Link>
                <Link
                  to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+nameštaj&potkategorije=Ugaone+garniture'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClicUgaone()}>Ugaone garniture</p>
                </Link>
                <Link
                  to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+nameštaj&potkategorije=trosed'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickTrosedi()}>Trosedi</p>
                </Link>
                <Link
                  to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+nameštaj&potkategorije=dvosed'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickDvosedi()}>Dvosedi</p>
                </Link>
                <Link
                  to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+nameštaj&potkategorije=fotelja'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickFotelje()}>Fotelje</p>
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} xl={2}>
            <div className='overlay-item'>
              <Image className='overlay-image icon-nav' src={tv} fluid />

              <Link
                to='https://www.elitehome.rs/tvVitrine?kategorije=TV+vitrine'
                className='my-link'
              >
                <p
                  onClick={() => handleLinkClickTvVitrine()}
                  className='overlay-text'
                >
                  TV vitrine
                </p>
              </Link>
              <div className='overlay-links'>
                <Link
                  to='https://www.elitehome.rs/tvVitrine?kategorije=TV+vitrine'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickTvVitrine()}>TV vitrine</p>
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} xl={2}>
            <div className='overlay-item'>
              <Image
                className='overlay-image icon-nav'
                src={diningroom}
                fluid
              />

              <Link
                to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=trpezarije'
                className='my-link'
              >
                <p
                  onClick={() => handleLinkClickTrpezarije()}
                  className='overlay-text'
                >
                  Trpezarije
                </p>
              </Link>
              <div className='overlay-links'>
                <Link
                  to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=sto'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickSto()}>Stolovi</p>
                </Link>
                <Link
                  to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=stolica'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickStolica()}>Stolice</p>
                </Link>

                <Link
                  to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=komoda'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickKomoda()}>Komode</p>
                </Link>

                <Link
                  to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=ogledalo'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickOgledalo()}>Ogledala</p>
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} xl={2}>
            <div className='overlay-item'>
              <Image className='overlay-image icon-nav' src={bedroom} fluid />

              <Link
                to='https://www.elitehome.rs/spavaceSobe?kategorije=spavaće+sobe&potkategorije=spavaća+soba'
                className='my-link'
              >
                <p
                  onClick={() => handleLinkClickSpavaca()}
                  className='overlay-text'
                >
                  Spavaće sobe
                </p>
              </Link>
              <div className='overlay-links'>
                <Link
                  to='https://www.elitehome.rs/spavaceSobe?kategorije=spavaće+sobe&potkategorije=ležaj'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickSpavacaLezaj()}>
                    Francuski ležajevi
                  </p>
                </Link>
                <Link
                  to='https://www.elitehome.rs/spavaceSobe?kategorije=spavaće+sobe&potkategorije=garderober'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickSpavacaGarderober()}>
                    Garderoberi
                  </p>
                </Link>
                <Link
                  to='https://www.elitehome.rs/spavaceSobe?kategorije=spavaće+sobe&potkategorije=noćni+ormarić'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickSpavacaNocniOrmaric()}>
                    Noćni ormarići
                  </p>
                </Link>
                <Link
                  to='https://www.elitehome.rs/spavaceSobe?kategorije=spavaće+sobe&potkategorije=komoda'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickSpavacaKomode()}>
                    Komode za šminkanje
                  </p>
                </Link>
                <Link
                  to='https://www.elitehome.rs/spavaceSobe?kategorije=spavaće+sobe&potkategorije=ogledalo'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickSpavacaOgledala()}>
                    Ogledala
                  </p>
                </Link>
                {/* <a href='/'>Dušeci</a> */}
              </div>
            </div>
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} xl={2}>
            <div className='overlay-item'>
              <Image className='overlay-image icon-nav' src={table} fluid />

              <Link
                to='https://www.elitehome.rs/klubSto?kategorije=klub+stolovi&potkategorije=klub+sto'
                className='my-link'
              >
                <p
                  onClick={() => handleLinkClickKlubSto()}
                  className='overlay-text'
                >
                  Klub stolovi
                </p>
              </Link>
              <div className='overlay-links'>
                <Link
                  to='https://www.elitehome.rs/klubSto?kategorije=klub+stolovi&potkategorije=klub+sto'
                  className='my-link'
                >
                  <p onClick={() => handleLinkClickKlubSto()}>Klub stolovi</p>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OverlayNav;
