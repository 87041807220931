import { Navigate, createBrowserRouter } from "react-router-dom";
import App from "../../App";
import Main from "../../components/main/Main";
import TapaciraniNamestajList from "../../components/tapaciraniNamestaj/TapaciraniNamestajList";
import TvVitrineList from "../../components/tvVitrine/TvVitrineList";
import TapaciraniNamestajProductCard from "../../components/tapaciraniNamestaj/TapaciraniNamestajProductCard";
import Kontakt from "../../components/kontakt/Kontakt";
import ServerError from "../errors/ServerError";
import NotFound from "../errors/NotFound";
import Onama from "../../components/onama/Onama";
import TrpezarijeList from "../../components/trpezarije/TrpezarijeList";
import SpavaceSobeList from "../../components/spavaceSobe/SpavaceSobeList";
import KlubStoList from "../../components/klubSto/KlubStoList";
import KlubStoProductCard from "../../components/klubSto/KlubStoProductCard";
import TrpezarijeProductCard from "../../components/trpezarije/TrpezarijeProductCard";
import TvVitrineProductCard from "../../components/tvVitrine/TvVitrineProductCard";
import SpavaceProductCard from "../../components/spavaceSobe/SpavaceProductCard";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "", element: <Main /> },
      { path: "tapaciraniNamestaj", element: <TapaciraniNamestajList /> },
      {
        path: "tapaciraniNamestaj/:id",
        element: <TapaciraniNamestajProductCard />,
      },
      { path: "tvVitrine", element: <TvVitrineList /> },
      { path: "tvVitrine/:id", element: <TvVitrineProductCard /> },
      { path: "trpezarije", element: <TrpezarijeList /> },
      { path: "trpezarije/:id", element: <TrpezarijeProductCard /> },
      { path: "spavaceSobe", element: <SpavaceSobeList /> },
      { path: "spavaceSobe/:id", element: <SpavaceProductCard /> },
      { path: "klubsto", element: <KlubStoList /> },
      { path: "klubSto/:id", element: <KlubStoProductCard /> },
      { path: "kontakt", element: <Kontakt /> },
      { path: "onama", element: <Onama /> },
      { path: "server-error", element: <ServerError /> },
      { path: "not-found", element: <NotFound /> },
      { path: "*", element: <Navigate replace to='/not-found' /> },
    ],
  },
]);
