import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { setPageNumber } from "../components/tapaciraniNamestaj/tNamestajSlice";
import { useDispatch } from "react-redux";

const CheckboxButtonsSpavace = ({ checked, onChange }) => {
  const [checkedItems, setCheckedItems] = useState(checked || []);

  const dispatch = useDispatch();

  useEffect(() => {
    setCheckedItems(checked || []);
  }, [checked]);

  function handleChecked(value) {
    if (checkedItems.includes(value)) {
      const newChecked = checkedItems.filter((item) => item !== value);
      setCheckedItems(newChecked);
      onChange(newChecked);
    } else {
      setCheckedItems([value]);
      onChange([value]);
    }

    dispatch(setPageNumber({ pageNumber: 1 }));
  }

  const tNamestajCheckbox = [
    { id: "ležaj", label: "Francuski ležajevi" },
    { id: "garderober", label: "Garderoberi" },
    { id: "noćni ormarić", label: "Noćni ormarići" },
    { id: "komoda", label: "Komode za šminkanje" },
    { id: "ogledalo", label: "Ogledala" },
  ];

  return (
    <>
      {" "}
      <p>Potkategorije</p>
      <Form>
        {tNamestajCheckbox.map((item) => (
          <Form.Check
            checked={checkedItems.indexOf(item.id) !== -1}
            onChange={() => handleChecked(item.id)}
            key={item.id}
            className='my-2'
            type='checkbox'
            id={item.id}
            label={item.label}
          />
        ))}
      </Form>
    </>
  );
};

export default CheckboxButtonsSpavace;
