import { Col, Container, Row } from "react-bootstrap";
import { FaInstagram, FaFacebook, FaTiktok } from "react-icons/fa";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className='footerbg mt-5'>
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <div className='mt-5'>
              <h5 className='h5Footer'>Elite Home</h5>
              <hr className='hrFooter1' />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} lg={4}>
            <div className='mt-5'>
              <h5 className='h6FooterL'>O nama</h5>
              <hr className='hrFooter' />
              <p className='pFooter'>
                Generalni distributer ekskluzivnog nameštaja vodećih turskih
                brendova prepoznatljivih u Londonu, Beču, Parizu, Berlinu...
                Oplemenite svoj životni prostor, uplovite sa nama u svet dizajna
                i komfora.
              </p>
            </div>
          </Col>
          <Col md={4} lg={4}>
            <div className='mt-5'>
              <h5 className='h6FooterC'>Mapa sajta</h5>
              <div className='dFlexCenterHr'>
                <hr className='hrFooterC' />
              </div>
              <ul className='list-unstyledC'>
                <li>
                  <Link to='/' className='my-link-footer'>
                    <p
                      onClick={() => window.scrollTo(0, 0)}
                      className='pointer'
                    >
                      Početna
                    </p>
                  </Link>
                </li>
                <li>
                  <Link
                    to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+nameštaj&potkategorije=Setovi'
                    className='my-link-footer'
                  >
                    <p
                      onClick={() => window.scrollTo(0, 0)}
                      className='pointer'
                    >
                      Setovi
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to='/onama' className='my-link-footer'>
                    <p
                      onClick={() => window.scrollTo(0, 0)}
                      className='pointer'
                    >
                      O nama
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to='/kontakt' className='my-link-footer'>
                    <p
                      onClick={() => window.scrollTo(0, 0)}
                      className='pointer'
                    >
                      Kontakt
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={4} lg={4}>
            <div className='footerMT'>
              <h5 className='h6FooterC'>Da li trebate pomoc?</h5>
              <hr className='hrFooterR' />
              <div className='infoHolder'>
                <ul className='list-unstyled'>
                  <div className='infoPosition'>
                    <li>Mur bb</li>
                    <li>36 300 Novi Pazar, Srbija</li>
                    <li>info@elitehome.rs</li>
                    <li>+381 69 10 99999</li>
                    <li>+381 60 03 89087</li>
                    <li>Radno vreme:</li>
                    <li>pon - pet 10:00 - 18:00</li>
                    <li>sub 10:00 - 17:00</li>
                  </div>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <div className='mt-5'>
              <hr className='hrFooter1' />
              <div className='icons'>
                <h5 className='h6FooterL'>Pratite nas:</h5>
                <div className='socialIcons'>
                  <Link
                    to='https://www.instagram.com/elitehome.rs/'
                    className='my-link-footer'
                    target='_blank'
                  >
                    <FaInstagram className='instagramIcon' />
                  </Link>
                  <Link
                    to='https://m.facebook.com/elitehome.rs/'
                    className='my-link-footer'
                    target='_blank'
                  >
                    <FaFacebook className='instagramIcon' />
                  </Link>
                  <Link
                    to='https://www.tiktok.com/@elitehome.rs/'
                    className='my-link-footer'
                    target='_blank'
                  >
                    <FaTiktok className='instagramIcon' />
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} lg={12}>
            <p className='text-center pf'>
              Copyright © {new Date().getFullYear()} Elite Home <br />
              Sva prava zadržana
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
