import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import HeaderNamestaj from "../../components/Header/HeaderNamestaj";
import Footer from "../../components/footer/Footer";
import { useLocation } from "react-router-dom";

import serverError from "../../namestaj/alerts.png";

export default function ServerError() {
  const { state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeaderNamestaj />
      <Container>
        <Row>
          <Col>
            {state?.error ? (
              <>
                <h3 style={{ color: "red" }}>{state.error.title}</h3>
                <hr />
                <p>{state.error.detail || "Internal server error"}</p>
              </>
            ) : (
              <>
                <div className='not-found'>
                  <Image src={serverError} fluid />
                  <h3 style={{ color: "red" }}>Server error!</h3>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
