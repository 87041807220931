import React, { useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  setPageNumber,
  setTNamestajParams,
} from "./tapaciraniNamestaj/tNamestajSlice";

export default function SearchField() {
  const [searchTerm, setSearchTerm] = useState();
  const dispatch = useDispatch();

  const handleSearch = () => {
    dispatch(setTNamestajParams({ searchTerm }));

    dispatch(setPageNumber({ pageNumber: 1 }));
  };

  return (
    <InputGroup className='mb-3'>
      <FormControl
        placeholder='Naziv proizvoda...'
        aria-label='Pretraži'
        aria-describedby='basic-addon2'
        value={searchTerm || ""}
        onChange={(event) => setSearchTerm(event.target.value)}
      />
      <Button onClick={handleSearch} className='searchButton'>
        Pretraga
      </Button>
    </InputGroup>
  );
}
