import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setPageNumber } from "../components/tapaciraniNamestaj/tNamestajSlice";

const CheckboxButtonsTrpezarije = ({ checked, onChange }) => {
  const [checkedItems, setCheckedItems] = useState(checked || []);

  const dispatch = useDispatch();

  useEffect(() => {
    setCheckedItems(checked || []);
  }, [checked]);

  function handleChecked(value) {
    // Ako je već čekiran, izbriši ga iz liste
    if (checkedItems.includes(value)) {
      const newChecked = checkedItems.filter((item) => item !== value);
      setCheckedItems(newChecked);
      onChange(newChecked);
    } else {
      // Ako nije čekiran, postavi samo ovaj čekiran i resetuj potkategorije
      setCheckedItems([value]);
      onChange([value]);
    }
    dispatch(setPageNumber({ pageNumber: 1 }));
  }

  const tNamestajCheckbox = [
    { id: "sto", label: "Stolovi" },
    { id: "stolica", label: "Stolice" },
    { id: "komoda", label: "Komode" },
    { id: "ogledalo", label: "Ogledala" },
  ];

  return (
    <>
      {" "}
      <p>Potkategorije</p>
      <Form>
        {tNamestajCheckbox.map((item) => (
          <Form.Check
            checked={checkedItems.indexOf(item.id) !== -1}
            onChange={() => handleChecked(item.id)}
            key={item.id}
            className='my-2'
            type='checkbox'
            id={item.id}
            label={item.label}
          />
        ))}
      </Form>
    </>
  );
};

export default CheckboxButtonsTrpezarije;
