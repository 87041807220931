import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import slika from "../../namestaj/spavacamain.jpg";
import "./SpavaceSobe.css";
import Footer from "../footer/Footer";
import HeaderNav from "../Header/HeaderNav";
import { useLocation } from "react-router";
import TapaciraniNamestajCard from "../tapaciraniNamestaj/TapaciraniNamestajCard";
import SearchField from "../SearchField";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTNamestajAsync,
  setPageNumber,
  setTNamestajParams,
  tNamestajSelectors,
} from "../tapaciraniNamestaj/tNamestajSlice";
import PaginationProducts from "../../app/layout/PaginationProducts";
import DisplayItemsText from "../../app/layout/DisplayItemsText";
import ScrollAnimationX from "../ScrollAnimationX";
import CheckboxButtonsSpavace from "../CheckboxButtonsSpavace";
import SpavaceSobeCard from "./SpavaceSobeCard";

export default function SpavaceSobeList() {
  const products = useSelector(tNamestajSelectors.selectAll);
  const { tNamestajLoaded, metaData, productParams } = useSelector(
    (state) => state.tNamestaj
  );
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const kategorije = queryParams.getAll("kategorije");
    const potkategorije = queryParams.getAll("potkategorije");

    if (kategorije.length > 0 || potkategorije.length > 0) {
      dispatch(setTNamestajParams({ kategorije, potkategorije }));
    }
  }, [location.search, dispatch]);

  useEffect(() => {
    if (!tNamestajLoaded) dispatch(fetchTNamestajAsync());
  }, [tNamestajLoaded, dispatch]);

  return (
    <>
      <HeaderNav stranica={"namestaj"} />

      {!metaData ? (
        <LoadingComponent />
      ) : (
        <>
          <Card className='mainImage' style={{ position: "relative" }}>
            <div
              style={{
                height: "400px",
              }}
            >
              <Card.Img
                className='mainImage'
                src={slika}
                alt='Slika'
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  background:
                    "linear-gradient(rgba(53, 53, 53, 0.427), rgba(58, 58, 58, 0.4))",
                  filter: "brightness(70%)",
                }}
              />
            </div>
            <div className='titleHandlerSpavaca'>
              <ScrollAnimationX sec={0.7}>
                <h2 className='h3TapaciraniNamestaj'>Spavaće sobe</h2>
              </ScrollAnimationX>
            </div>
          </Card>

          <Container>
            <Row>
              <Col>
                <p className='navRoute my-3'>Početna / Spavaće sobe</p>
                <DisplayItemsText metaData={metaData} />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={4} lg={3} className='d-flex flex-column h-100'>
                <div className='flex-grow-1'>
                  {/* Sadržaj filtera */}

                  <ScrollAnimationX sec={1}>
                    <SearchField />
                  </ScrollAnimationX>
                  <ScrollAnimationX sec={1.3}>
                    <CheckboxButtonsSpavace
                      checked={productParams.potkategorije}
                      onChange={(items) =>
                        dispatch(setTNamestajParams({ potkategorije: items }))
                      }
                    />
                  </ScrollAnimationX>
                </div>
              </Col>
              <Col sm={12} md={8} lg={9}>
                <Row className='borderLeft'>
                  {products.map((product) => (
                    <Col key={product.proizvodId} sm={12} md={6} lg={4} xl={4}>
                      <SpavaceSobeCard product={product} />
                    </Col>
                  ))}
                </Row>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  {products.length > 0 ? (
                    <PaginationProducts
                      metaData={metaData}
                      onPageChange={(page) =>
                        dispatch(setPageNumber({ pageNumber: page }))
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}

      <Footer />
    </>
  );
}
