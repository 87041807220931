import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import slika from "../../Images/_mzf2100440_PSMS copy.jpg";
import "./Onama.css";
import Footer from "../footer/Footer";
import HeaderNav from "../Header/HeaderNav";
import ScrollAnimationX from "../ScrollAnimationX";
import ScrollAnimationXRight from "../ScrollAnimationXRight";
import ScrollAnimationY from "../ScrollAnimationY";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GrMail } from "react-icons/gr";
import { AiFillSafetyCertificate } from "react-icons/ai";

export default function Onama() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderNav stranica={"onama"} />

      <Card className='mainImage' style={{ position: "relative" }}>
        <div
          style={{
            height: "400px",
          }}
        >
          <Card.Img
            className='mainImage'
            src={slika}
            alt='Slika'
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              background:
                "linear-gradient(rgba(53, 53, 53, 0.427), rgba(58, 58, 58, 0.4))",
              filter: "brightness(70%)",
            }}
          />
        </div>
        <div className='titleHandlerOnama'>
          <ScrollAnimationX sec={0.7}>
            <h2 className='h3TapaciraniNamestaj'>O nama</h2>
          </ScrollAnimationX>
        </div>
      </Card>

      <Container>
        <Row>
          <Col>
            <p className='navRoute my-3'>Početna / O nama</p>
          </Col>
        </Row>

        <Row>
          <div className='colG-6'>
            <h2 className='h2Proizvodi'>O nama</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <hr className='hrProizvodi' />
            </div>
            <ScrollAnimationX sec={0.7}>
              <p className='pelite'>Elite Home Furniture</p>
            </ScrollAnimationX>
          </div>
          <div className='colG-3 mt-5'>
            <div className='wrapper'>
              <ScrollAnimationX sec={0.7}>
                <div className='flex-contact'>
                  <p className='conp'>
                    Ekskluzivan salon nameštaja u Novom Pazaru sa savremenim
                    načinom prodaje i prezentacije proizvoda kroz kreiranje
                    prostora koji odražava moderan koncept življenja. Generalni
                    distributer ekskluzivnog nameštaja vodećih turskih brendova
                    prepoznatljivih u Londonu, Beču, Parizu, Berlinu...
                    Oplemenite svoj životni prostor, uplovite sa nama u svijet
                    dizajna i komfora.
                  </p>
                </div>
              </ScrollAnimationX>
            </div>
          </div>
          <div className='colG-3 mt-5'>
            <div className='wrapper'>
              <ScrollAnimationXRight sec={0.7}>
                <div className='flex-contact'>
                  <p className='conp'>
                    U našoj ponudi možete pronaći udobne sofe, elegantne stolove
                    i praktične ormare koji će oplemeniti vaš prostor. Uživajte
                    u funkcionalnosti i estetici s našim vrhunskim nameštajem
                    koji će stvoriti željeni ambijent u vašem domu.
                  </p>
                </div>
              </ScrollAnimationXRight>
            </div>
          </div>
        </Row>
        <Row>
          <div className='colG-2 mt-5'>
            <div className='wrapper'>
              <ScrollAnimationX sec={0.7}>
                <div className='flex-contact'>
                  <h1>
                    <FaPhoneAlt />
                  </h1>
                  <div className='flex-contact1'>
                    <h5 className='con-style'>Podrška kupcima</h5>
                    <p>
                      <a
                        className='conp1'
                        target='_blank'
                        href='tel:+381 69 10 99999'
                        rel='noreferrer'
                      >
                        + 381 69 10 99999
                      </a>
                    </p>
                  </div>
                </div>
              </ScrollAnimationX>
            </div>
          </div>

          <div className='colG-2 mt-5'>
            <div className='wrapper'>
              <ScrollAnimationY sec={0.7}>
                <div className='flex-contact'>
                  <h1>
                    <FaLocationDot />
                  </h1>
                  <div className='flex-contact1'>
                    <h5 className='con-style'>Adresa</h5>
                    <p>
                      <a
                        className='conp1'
                        target='_blank'
                        rel='noreferrer'
                        href='http://maps.google.com/?q=Salon nameštaja ELITE HOME, Mur bb, Novi Pazar 36300'
                      >
                        Mur bb, Novi Pazar
                      </a>
                    </p>
                  </div>
                </div>
              </ScrollAnimationY>
            </div>
          </div>
          <div className='colG-2 mt-5'>
            <div className='wrapper'>
              <ScrollAnimationXRight sec={0.7}>
                <div className='flex-contact'>
                  <h1>
                    <AiFillSafetyCertificate />
                  </h1>
                  <div className='flex-contact1'>
                    <h5 className='con-style'>100% kvalitet</h5>

                    <p className='conp1'>Elite Home</p>
                  </div>
                </div>
              </ScrollAnimationXRight>
            </div>
          </div>
        </Row>
      </Container>

      <Footer />
    </>
  );
}
