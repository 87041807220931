import React from "react";

export default function DisplayItemsText({ metaData }) {
  const { currentPage, totalCount, pageSize } = metaData;
  return (
    <p className='showResult my-3'>
      Prikaz {(currentPage - 1) * pageSize + 1}-
      {currentPage * pageSize > totalCount
        ? totalCount
        : currentPage * pageSize}{" "}
      od {totalCount} rezultata
    </p>
  );
}
