import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import slika from "../../Images/Contact-usn.jpg";
import "./Kontakt.css";
import FooterKontakt from "../footer/FooterKontakt";
import HeaderNav from "../Header/HeaderNav";
import ScrollAnimationX from "../ScrollAnimationX";
import ScrollAnimationXRight from "../ScrollAnimationXRight";
import ScrollAnimationY from "../ScrollAnimationY";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GrMail } from "react-icons/gr";

export default function Kontakt() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderNav stranica={"kontakt"} />

      <Card className='mainImage' style={{ position: "relative" }}>
        <div
          style={{
            height: "400px",
          }}
        >
          <Card.Img
            className='mainImage'
            src={slika}
            alt='Slika'
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              background:
                "linear-gradient(rgba(53, 53, 53, 0.427), rgba(58, 58, 58, 0.4))",
              filter: "brightness(70%)",
            }}
          />
        </div>
        <div className='titleHandlerKontakt'>
          <ScrollAnimationX sec={0.7}>
            <h2 className='h3TapaciraniNamestaj'>Kontakt</h2>
          </ScrollAnimationX>
        </div>
      </Card>

      <Container>
        <Row>
          <Col>
            <p className='navRoute my-3'>Početna / Kontakt</p>
          </Col>
        </Row>

        <Row>
          <div className='colG-6'>
            <h2 className='h2Proizvodi'>Kontaktirajte nas</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <hr className='hrProizvodi' />
            </div>
            <ScrollAnimationX sec={0.7}>
              <p className='conp'>
                Stuрite u kontakt sa nama. Ne možete da pronađete odgovor koji
                tražite?
                <br /> Ne ustručavajte se da nas pozovete.
              </p>
            </ScrollAnimationX>
          </div>
          <div className='colG-2 mt-5'>
            <div className='wrapper'>
              <ScrollAnimationX sec={0.7}>
                <div className='flex-contact'>
                  <h1>
                    <FaPhoneAlt />
                  </h1>
                  <div className='flex-contact1'>
                    <h5 className='con-style'>Telefon</h5>
                    <p>
                      <a
                        className='conp1'
                        target='_blank'
                        rel='noreferrer'
                        href='tel:+381 69 10 99999'
                      >
                        + 381 69 10 99999
                      </a>
                    </p>
                  </div>
                </div>
              </ScrollAnimationX>
            </div>
          </div>

          <div className='colG-2 mt-5'>
            <div className='wrapper'>
              <ScrollAnimationY sec={0.7}>
                <div className='flex-contact'>
                  <h1>
                    <FaLocationDot />
                  </h1>
                  <div className='flex-contact1'>
                    <h5 className='con-style'>Adresa</h5>
                    <p>
                      <a
                        className='conp1'
                        target='_blank'
                        rel='noreferrer'
                        href='http://maps.google.com/?q=Salon nameštaja ELITE HOME, Mur bb, Novi Pazar 36300'
                      >
                        Mur bb, Novi Pazar
                      </a>
                    </p>
                  </div>
                </div>
              </ScrollAnimationY>
            </div>
          </div>
          <div className='colG-2 mt-5'>
            <div className='wrapper'>
              <ScrollAnimationXRight sec={0.7}>
                <div className='flex-contact'>
                  <h1>
                    <GrMail />
                  </h1>
                  <div className='flex-contact1'>
                    <h5 className='con-style'>Email</h5>

                    <a
                      className='conp1'
                      target='_blank'
                      rel='noreferrer'
                      href='mailto:info@elitehome.rs'
                    >
                      info@elitehome.rs
                    </a>
                  </div>
                </div>
              </ScrollAnimationXRight>
            </div>
          </div>
        </Row>
      </Container>

      <Container fluid>
        <div className='map mt-5'>
          <div className='product-title'>
            <h2 className='h2Proizvodi'>Naša lokacija</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <hr className='hrProizvodi' />
            </div>
          </div>
          <iframe
            className='googlemap mt-3'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2912.428113808426!2d20.488248515480493!3d43.11653197914349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13532d50bedc6881%3A0xd2bb168cf8725881!2ssalon%20name%C5%A1taja%20ELITE%20HOME!5e0!3m2!1sde!2srs!4v1649610856624!5m2!1sde!2srs'
            width='600'
            height='450'
            allowFullScreen
            loading='lazy'
            title='mapa'
            referrerPolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </Container>
      <FooterKontakt />
    </>
  );
}
