import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Image, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTNamestajProductAsync,
  tNamestajSelectors,
} from "../tapaciraniNamestaj/tNamestajSlice";

import HeaderNamestaj from "../Header/HeaderNamestaj";
import Footer from "../footer/Footer";
import NotFound from "../../app/errors/NotFound";
import LoadingComponent from "../../app/layout/LoadingComponent";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ScrollAnimationX from "../ScrollAnimationX";
import ScrollAnimationXRight from "../ScrollAnimationXRight";
import { FreeMode, Pagination } from "swiper";

import tvdio from "../../Images/dio1.jpg";
import cavalli from "../../Images/cavalliSet1.jpg";
import setSenfoni from "../../Images/senfoni1.jpg";
import minotti from "../../Images/minotti1.jpg";
import tvOdessa from "../../Images/odessa1.jpg";
import play from "../../Images/PlayUgaona1.jpg";
import spdio from "../../Images/spdio1.jpg";
import tvvalentino from "../../Images/tvvalentino1.jpg";

export default function TrpezarijeProductCard() {
  const [slidesPerView, setSlidesPerView] = useState(getInitialSlidesPerView());

  function getInitialSlidesPerView() {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 576) {
      return 1;
    } else if (screenWidth <= 768) {
      return 2;
    } else if (screenWidth <= 992) {
      return 3;
    } else {
      return 4;
    }
  }
  useEffect(() => {
    function handleResize() {
      setSlidesPerView(getInitialSlidesPerView());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { id } = useParams();
  const product = useSelector((state) =>
    tNamestajSelectors.selectById(state, id)
  );
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.tNamestaj);
  const [sliderIndex, setSliderIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!product) dispatch(fetchTNamestajProductAsync(parseInt(id)));
  }, [id, dispatch, product]);

  const message = "Proizvod nije pronadjen.";

  const handleClick = (index) => {
    setSliderIndex(index);
  };

  const potkategorija = product?.potkategorija;
  const kategorija = potkategorija?.kategorija;
  const elementi = product?.elementi;
  const slike = product?.slike || [];
  const potkategorijaNaziv = product?.potkategorija.naziv;

  return (
    <>
      <HeaderNamestaj stranica={"namestaj"} />

      {status.includes("pendingFetchTNamestajProduct") ? (
        <LoadingComponent />
      ) : !product ? (
        <NotFound message={message} />
      ) : (
        <>
          <Container>
            <Row>
              <Col>
                <p className='navRoute my-3'>
                  Početna / {kategorija.naziv} / {potkategorija.naziv} /{" "}
                  {product.naziv}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <ScrollAnimationX sec={0.6}>
                  <div className='slider'>
                    {slike.map((data, i) => (
                      <div
                        key={data.slikaId}
                        className={`${
                          potkategorijaNaziv === "Stolica" ? "imgFlex" : ""
                        } slider-container slider-item ${
                          sliderIndex === i ? "active" : ""
                        }`}
                        style={{
                          transform: `translateX(-${sliderIndex * 100}%)`,
                        }}
                      >
                        <Image
                          fluid
                          className={`${
                            potkategorijaNaziv === "Stolica"
                              ? "trpezarijaProductCardImg"
                              : ""
                          }`}
                          src={data.putanjaSlike}
                        />
                      </div>
                    ))}
                  </div>
                  <div className='thumbnail-container'>
                    <Swiper
                      slidesPerView={
                        slike.length >= 4
                          ? 4
                          : slike.length >= 3
                          ? 3
                          : slike.length >= 2
                          ? 2
                          : slike.length >= 1
                          ? 2
                          : 1
                      }
                      spaceBetween={1}
                      modules={[Navigation]}
                    >
                      {slike.length > 4 ? (
                        <>
                          <div
                            className='swiper-button-prev'
                            slot='container-start'
                          ></div>
                          <div
                            className='swiper-button-next'
                            slot='container-end'
                          ></div>
                        </>
                      ) : (
                        ""
                      )}

                      {slike.map((data, i) => (
                        <SwiperSlide key={data.slikaId}>
                          <Image
                            src={data.putanjaSlike}
                            height='180'
                            width='180'
                            fluid
                            onClick={() => handleClick(i)}
                            className={`thumbnail ${
                              sliderIndex === i ? "active" : ""
                            }`}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </ScrollAnimationX>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <ScrollAnimationXRight sec={0.6}>
                  <div style={{ textAlign: "center" }}>
                    <h2>{product.naziv}</h2>
                    <hr />
                    <h4>Elementi:</h4>
                    <h5>
                      {elementi.length > 0
                        ? elementi
                            .map((element, index) => {
                              if (index === 0) {
                                return (
                                  element.naziv.charAt(0).toUpperCase() +
                                  element.naziv.slice(1)
                                );
                              } else {
                                return element.naziv.toLowerCase();
                              }
                            })
                            .join(", ")
                        : ""}
                    </h5>
                    <hr />
                    <h4>
                      Materijal:{" "}
                      {elementi.map((element) => element.materijal)[0]}
                    </h4>
                    <hr />{" "}
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Elementi</th>
                          <th>Dimenzije</th>
                        </tr>
                      </thead>
                      <tbody>
                        {elementi.map((element, index) => (
                          <tr key={index}>
                            <td>{element.naziv}</td>
                            <td>{element.dimenzija}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </ScrollAnimationXRight>
              </Col>
            </Row>
            <Row>
              <h2 className='h2Proizvodi mt-proizvodi'>Ostali proizvodi</h2>
              <div className='dflexCenter'>
                <hr className='hrProizvodi' />
              </div>
              <section className='my-5'>
                <Swiper
                  slidesPerView={slidesPerView}
                  spaceBetween={1}
                  freeMode={false}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination]}
                  className='mySwiper'
                >
                  <SwiperSlide key={1259}>
                    <Link to={`/tvVitrine/87`} className='my-link'>
                      <div className='swiperSlideHover'>
                        <div className='dflexCenter '>
                          <Image
                            className='image_slider_proizvodi'
                            src={tvdio}
                            fluid
                          />
                        </div>
                        <div className='sliderh4'>
                          <h4 className='sliderh4'>TV vitrina Dio</h4>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide key={1212}>
                    <Link to={`/tapaciraniNamestaj/40`} className='my-link'>
                      <div className='swiperSlideHover'>
                        <div className='dflexCenter '>
                          <Image
                            className='image_slider_proizvodi'
                            src={cavalli}
                            fluid
                          />
                        </div>
                        <div className='sliderh4'>
                          <h4 className='sliderh4'>Set Cavalli</h4>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide key={1178}>
                    <Link to={`/spavaceSobe/5`} className='my-link'>
                      <div className='swiperSlideHover'>
                        <div className='dflexCenter '>
                          <Image
                            className='image_slider_proizvodi'
                            src={setSenfoni}
                            fluid
                          />
                        </div>
                        <div className='sliderh4'>
                          <h4 className='sliderh4'>Set Senfoni</h4>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide key={1371}>
                    <Link to={`/klubsto/204`} className='my-link'>
                      <div className='swiperSlideHover'>
                        <div className='dflexCenter '>
                          <Image
                            className='image_slider_proizvodi'
                            src={minotti}
                            fluid
                          />
                        </div>
                        <div className='sliderh4'>
                          <h4 className='sliderh4'>Klub sto Minotti</h4>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide key={1261}>
                    <Link to={`/tvVitrine/89`} className='my-link'>
                      <div className='swiperSlideHover'>
                        <div className='dflexCenter '>
                          <Image
                            className='image_slider_proizvodi'
                            src={tvOdessa}
                            fluid
                          />
                        </div>
                        <div className='sliderh4'>
                          <h4 className='sliderh4'>TV vitrina Odessa</h4>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide key={1221}>
                    <Link to={`/tapaciraniNamestaj/48`} className='my-link'>
                      <div className='swiperSlideHover'>
                        <div className='dflexCenter '>
                          <Image
                            className='image_slider_proizvodi'
                            src={play}
                            fluid
                          />
                        </div>
                        <div className='sliderh4'>
                          <h4 className='sliderh4'>Ugaona Play</h4>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide key={1177}>
                    <Link to={`/spavaceSobe/4`} className='my-link'>
                      <div className='swiperSlideHover'>
                        <div className='dflexCenter '>
                          <Image
                            className='image_slider_proizvodi'
                            src={spdio}
                            fluid
                          />
                        </div>
                        <div className='sliderh4'>
                          <h4 className='sliderh4'>Set Dio</h4>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                  <SwiperSlide key={1264}>
                    <Link to={`/tvVitrine/92`} className='my-link'>
                      <div className='swiperSlideHover'>
                        <div className='dflexCenter '>
                          <Image
                            className='image_slider_proizvodi'
                            src={tvvalentino}
                            fluid
                          />
                        </div>
                        <div className='sliderh4'>
                          <h4 className='sliderh4'>TV vitrina Valentino</h4>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                </Swiper>
              </section>
            </Row>
          </Container>
        </>
      )}

      <Footer />
    </>
  );
}
